<template>
  <v-container>
    <div class="d-flex align-center flex-wrap mb-2" style="gap: 20px">
      <v-btn small color="primary" @click.stop="getItems">
        <v-icon left>mdi-reload</v-icon>
        {{ $t('common.recargar') }}
      </v-btn>
      <v-btn small color="primary" to="borradores/nuevo">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('borradores.nuevo') }}
      </v-btn>
    </div>

    <v-card>
      <app-table :search="search" :headers="headers" :items="items" item-key="ref" :loading="loading" show-select
        :page.sync="page" :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [15, 50, -1],
        }" hide-default-footer checkbox-color="secondary" @page-count="pageCount = $event" @click:row="item =>
  openDraft(item)
  ">
        <template v-slot:body.prepend="{ headers }">
          <table-filters :headers="headers" :items="items" v-model="inlineFilters" />
        </template>

        <template v-slot:item.fecha="{ item }">
          {{ item.fecha | date }}
        </template>

        <template v-slot:item.contrato_luz="{ item }">
          <v-icon color="success" v-if="item.contrato_luz">mdi-check</v-icon>
          <v-icon color="error" v-if="!item.contrato_luz">mdi-close</v-icon>
        </template>
        <template v-slot:item.contrato_gas="{ item }">
          <v-icon color="success" v-if="item.contrato_gas">mdi-check</v-icon>
          <v-icon color="error" v-if="!item.contrato_gas">mdi-close</v-icon>
        </template>

        <template v-slot:item.estado="{ item }">
          <EstadoContrato :estado="item.estado" />
        </template>

        <template v-slot:item.acciones="{ item }">
          <div v-if="item.estado == 'BORRADOR'" class="d-flex" style="gap: 5px">
            <v-btn :to="`borradores/nuevo?id=${item.ref}`" small color="secondary" rounded>
              <v-icon left>mdi-pencil</v-icon>
              {{ $t('common.editar') }}
            </v-btn>
          </div>
        </template>
      </app-table>
    </v-card>
    <!-- <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        total-visible="7"
      ></v-pagination>
    </div> -->
    <ConfirmDialog ref="confirm" />
  </v-container>
</template>

<script>
import { perColumnFilter, parseDate } from "@/utils/index.js";

export default {
  components: {
    EstadoContrato: () =>
      import("@/modules/contratos/components/EstadoContrato.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
    ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    AppTable: () => import("@/components/AppTable.vue"),
  },
  props: {
    _detailsId: String | Number,
  },
  data() {
    return {
      items: [],
      headers: [],
      search: null,
      desde: null,
      hasta: null,
      loading: false,
      page: 1,
      pageCount: null,
      detailsId: this._detailsId || null,

      inlineFilters: {},
    };
  },
  methods: {
    parseDate,
    async getItems() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `/borradores`,
      });

      this.items = data;
      this.loading = false;
    },
    async getHeaders() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: `/borradores/headers`,
      });

      (this.headers = data.map((header) => {
        return {
          class: "text-no-wrap sticky-header",
          cellClass: "pa-2 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        };
      })),
        (this.loading = false);
    },
    openDraft(item) {
      this.$router.push(`/borradores/nuevo?id=${item.ref}`)
    }
  },
  mounted() {
    this.getItems();
    this.getHeaders();
  },
};
</script>

<style></style>
